var popup = function() {
  this.triggers = null;

  this.init = function($id) {
    // Get triggers
    this.triggers = document.querySelectorAll($id);

    for(var $i = 0; $i < this.triggers.length; $i++) {
      // Get popup id
      var $popup = this.triggers[$i].getAttribute('data-popup-id');

      // Init event
      this.addEventOpen(this.triggers[$i], $popup);

      // Init event
      this.addEventClose($popup);
    }
  }

  this.addEventOpen = function($trigger, $popup) {
    // Add event open
    $trigger.onclick = function() {
      this.setState(document.getElementById($popup), $trigger.getAttribute('data-popup-title'));
      return false;
    }.bind(this);
  }

  this.addEventClose = function($popup) {
    // Get trigger
    var $trigger = document.getElementById($popup).querySelector('[data-popup-close]');
  
    // Add event close
    $trigger.onclick = function() {
      this.setState(document.getElementById($popup));
      return false;
    }.bind(this);
  }

  this.setState = function($popup, $title = null) {
    // Get state
    var $state = $popup.getAttribute('data-popup-state');

    // Set popup title
    $popup.querySelector('[data-popup-title]').innerHTML = $title;

    // Switch state
    if($state == 'is-not-active') {
      $popup.setAttribute('data-popup-state', 'is-active');
    } else {
      $popup.setAttribute('data-popup-state', 'is-not-active');
    }
  }
};

new popup().init('.js-popup');